/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import MapContext from '../../MapContext';
import Accordion from '../../Accordion/Accordion';
import DetailsButtons from './DetailsButtons';

const DetailsButtonsList = ({ mapFilterData, goToPrevPanel }) => {
  const [detailsData, setDetailsData] = useState([]);
  const [hasActiveFilters, setHasActiveFilters] = useState(false);
  const [pendingSelections, setPendingSelections] = useState({});
  const mapContextStuff = useContext(MapContext);
  const {
    activeLevelOfCareUuid,
    activeCategoryFilter,
    activeFilterSelections,
    setActiveFilterSelections,
  } = mapContextStuff;

  // Initialize pending selections with current active selections for this category
  useEffect(() => {
    if (activeFilterSelections[activeCategoryFilter]) {
      setPendingSelections({
        [activeCategoryFilter]: { ...activeFilterSelections[activeCategoryFilter] }
      });
    } else {
      setPendingSelections({});
    }
  }, [activeCategoryFilter, activeFilterSelections]);

  // Load and sort the details data from the original unfiltered data
  useEffect(() => {
    if (Object.keys(mapFilterData).length && activeLevelOfCareUuid && activeCategoryFilter) {
      const locData = mapFilterData[activeLevelOfCareUuid]?.children;
      const rawDetailsData = locData?.[activeCategoryFilter]?.children;

      if (rawDetailsData) {
        // Sort Items by Weight, then Alphabetically.
        const sorted = Object.values(rawDetailsData).sort((a, b) => (
          a.weight > b.weight
            ? 1
            : a.weight === b.weight
              ? a.label > b.label
                ? 1
                : -1
              : -1
        ));

        setDetailsData(sorted);
      } else {
        setDetailsData([]);
      }
    }
  }, [activeCategoryFilter, mapFilterData, activeLevelOfCareUuid]);

  // Set status when there are any active filters.
  useEffect(() => {
    setHasActiveFilters(Object.keys(activeFilterSelections).length > 0);
  }, [activeFilterSelections]);

  const handleClearAllFilters = () => {
    setActiveFilterSelections({});
    setPendingSelections({});
  };

  const handleDone = () => {
    // Apply pending selections to active selections
    const newFilterSelections = { ...activeFilterSelections };

    // Remove old selections for this category
    if (newFilterSelections[activeCategoryFilter]) {
      delete newFilterSelections[activeCategoryFilter];
    }

    // Add new selections if any
    if (pendingSelections[activeCategoryFilter]) {
      newFilterSelections[activeCategoryFilter] = pendingSelections[activeCategoryFilter];
    }

    setActiveFilterSelections(newFilterSelections);
  };

  // If we have no data, show the empty message.
  if (!detailsData?.length) {
    const emptyMessage = 'There are no options available for this category.';
    return (
      <Accordion
        title="Back to Categories"
        createBackButton
        goToPrevPanel={goToPrevPanel}
      >
        <div className="mapFilter--no-results">
          <div className="mapFilter--no-results-text type-cta-md">
            {emptyMessage}
          </div>

          {hasActiveFilters && (
            <button className="mapFilter--clear-filters type-cta-md" onClick={() => handleClearAllFilters()}>Clear all filters</button>
          )}
        </div>
      </Accordion>
    );
  }

  return (
    <Accordion
      title="Back to Categories"
      createBackButton
      goToPrevPanel={goToPrevPanel}
    >
      <div className="mapFilter--details-content">
        <div className="mapFilter--options-list">
          {detailsData.map((detailData) => (
            <DetailsButtons
              detailItemLabel={detailData.label}
              detailItemUuid={detailData.uuid}
              key={detailData.uuid}
              goToPrevPanel={goToPrevPanel}
              detailItemMeta={detailData.meta}
              isActive={activeFilterSelections[activeCategoryFilter]?.[detailData.uuid] !== undefined}
              pendingSelections={pendingSelections}
              setPendingSelections={(newSelections) => {
                setPendingSelections(newSelections);
                // Auto-apply filters whenever selections change
                const newFilterSelections = { ...activeFilterSelections };
                if (newFilterSelections[activeCategoryFilter]) {
                  delete newFilterSelections[activeCategoryFilter];
                }
                if (newSelections[activeCategoryFilter]) {
                  newFilterSelections[activeCategoryFilter] = newSelections[activeCategoryFilter];
                }
                setActiveFilterSelections(newFilterSelections);
              }}
            />
          ))}
        </div>
      </div>
    </Accordion>
  );
};

DetailsButtonsList.propTypes = {
  goToPrevPanel: PropTypes.func.isRequired,
  mapFilterData: PropTypes.object.isRequired,
};

export default DetailsButtonsList;
