import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import MapContext from '../../MapContext';

const DetailsButtons = ({
  detailItemLabel, detailItemUuid, goToPrevPanel, detailItemMeta, isActive, pendingSelections, setPendingSelections,
}) => {
  const mapContextStuff = useContext(MapContext);
  const {
    activeCategoryFilter,
    activeFilterSelections,
  } = mapContextStuff;

  // When a selection is made on the detail panel, we will toggle that selection
  // in the pending selections
  const handleClick = (event) => {
    const newPendingSelections = { ...pendingSelections };

    // Initialize the category if it doesn't exist
    if (!newPendingSelections[activeCategoryFilter]) {
      newPendingSelections[activeCategoryFilter] = {};
    }

    // Toggle the selection
    if (newPendingSelections[activeCategoryFilter][detailItemUuid]) {
      // If already selected, remove it
      delete newPendingSelections[activeCategoryFilter][detailItemUuid];
      // If category is now empty, remove it
      if (Object.keys(newPendingSelections[activeCategoryFilter]).length === 0) {
        delete newPendingSelections[activeCategoryFilter];
      }
    } else {
      // If not selected, add it with color information for floor plans
      if (activeCategoryFilter === 'floor_plan') {
        newPendingSelections[activeCategoryFilter][detailItemUuid] = {
          label: detailItemLabel,
          color: detailItemMeta.key_color ?? 'gray'
        };
      } else {
        newPendingSelections[activeCategoryFilter][detailItemUuid] = detailItemLabel;
      }
    }

    setPendingSelections(newPendingSelections);
  };

  // Check if the item is selected in either active or pending selections
  const isPendingActive = pendingSelections[activeCategoryFilter]?.[detailItemUuid] !== undefined;
  const displayMode = isPendingActive ? 'active' : 'disabled';

  const getOptionStyles = () => {
    if (activeCategoryFilter && activeCategoryFilter === 'floor_plan') {
      const floorPlanKeyColor = detailItemMeta.key_color ?? 'gray';
      return {
        backgroundColor: floorPlanKeyColor,
        borderColor: floorPlanKeyColor,
      };
    }
    return null;
  };

  return (
    <button
      className={`mapSelector-option mapFilter-option mapFilter-option--floorPlan type-cta-alt-md ${isPendingActive ? 'mapFilter-option--selected' : ''}`}
      onClick={handleClick}
      data-id={detailItemUuid}
      type="button"
    >
      <span
        className={`mapFilter-option--floorPlanColor mapFilter-option--${displayMode}`}
        style={getOptionStyles()}
      >
        {isPendingActive && (
          <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 8.5L7.75251 15.5L19.0144 2.18506" stroke="#ffffff" strokeWidth="2" strokeLinecap="square" />
          </svg>
        )}
      </span>
      {detailItemLabel}
    </button>
  );
};

DetailsButtons.propTypes = {
  detailItemLabel: PropTypes.string.isRequired,
  detailItemUuid: PropTypes.string.isRequired,
  goToPrevPanel: PropTypes.func.isRequired,
  detailItemMeta: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  pendingSelections: PropTypes.object.isRequired,
  setPendingSelections: PropTypes.func.isRequired,
};

export default DetailsButtons;
