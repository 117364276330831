/* eslint-disable import/prefer-default-export */
import { processSerializedThreeSixties } from '../VirtualTour/VirtualTourDetailNormalizeData';
import { includedPhotoGalleryImagesNormalizedData } from '../PhotoGallery/IncludedPhotoGalleryImagesNormalizedData';

export function includedPlaceNormalizedData( deserializedPlaceData, drupalIncluded ) {
  let place = {};

  if ( deserializedPlaceData ) {
    // coverImage
    let coverImage = null;
    let coverImageFieldData = null;
    const coverImageFieldId = deserializedPlaceData.fieldCoverPhoto?.id;
    if ( coverImageFieldId ) {
      coverImageFieldData = drupalIncluded.find(
        ( include ) => include.id === coverImageFieldId,
      );
      coverImage = coverImageFieldData?.links?.modal?.href;
    }

    // virtual tour
    const threeSixtiesData = deserializedPlaceData.fieldVirtualTour?.fieldThreeSixties;
    const threeSixties = processSerializedThreeSixties( threeSixtiesData );

    // video
    let video = null;
    if ( deserializedPlaceData?.fieldVideo?.fieldVideoService
      && deserializedPlaceData?.fieldVideo?.fieldVideoId ) {
      video = {
        serviceVideoId: deserializedPlaceData.fieldVideo.fieldVideoId,
        service: deserializedPlaceData.fieldVideo.fieldVideoService,
        autoplay: false,
      };
    }

    // photo gallery
    const deserializedImages = deserializedPlaceData?.fieldPhotoGallery?.fieldGalleryPhotos;
    const galleryId = deserializedPlaceData?.fieldPhotoGallery?.id;
    const includedIndex = drupalIncluded.findIndex( ( include ) => include.id === galleryId );
    let photoGalleryImages = includedPhotoGalleryImagesNormalizedData(
      deserializedImages,
      includedIndex,
      drupalIncluded,
    );

    // Show the cover image if there no virtual tour, video or photo gallery.
    if ( threeSixties.length === 0 && !video && photoGalleryImages.length === 0 && coverImage ) {
      photoGalleryImages = [ {
        id: coverImageFieldId,
        image: {
          modal: coverImageFieldData?.links?.modal?.href,
        },
        caption: null,
      } ];
    }

    place = {
      id: deserializedPlaceData.id,
      title: deserializedPlaceData.title,
      description: deserializedPlaceData.fieldDescription,
      coverImage,
      threeSixties,
      video,
      photoGalleryImages,
    };
  }

  return place;
}
