/* eslint-disable import/prefer-default-export */
import { processSerializedThreeSixties } from '../VirtualTour/VirtualTourDetailNormalizeData';
import { includedPlaceNormalizedData } from './IncludedPlaceNormalizedData';

export function includedPlacesNormalizedData( deserializedIncludedPlacesData, drupalIncluded ) {
  let places = [];

  if ( deserializedIncludedPlacesData ) {
    // Massage the data into the shape we care about for places.
    places = deserializedIncludedPlacesData.map( ( deserializedPlace ) => {

      // // coverImage
      // let coverImage = null;
      // const coverImageFieldId = place.fieldCoverPhoto?.id;
      // if ( coverImageFieldId ) {
      //   const coverImageFieldData = drupalIncluded.find(
      //     ( include ) => include.id === coverImageFieldId,
      //   );
      //   // coverImage = {
      //   //   oneThirdScreen: coverImageFieldData?.links?.one_third_screen?.href,
      //   //   modal: coverImageFieldData?.links?.modal?.href,
      //   // };
      //   coverImage = coverImageFieldData?.links?.modal?.href;
      // }

      // // virtual tour
      // const threeSixtiesData = place.fieldVirtualTour?.fieldThreeSixties;
      // const threeSixties = processSerializedThreeSixties( threeSixtiesData );

      // // video
      // let video = null;
      // if ( place?.fieldVideo?.fieldVideoService && place?.fieldVideo?.fieldVideoId ) {
      //   video = {
      //     serviceVideoId: place.fieldVideo.fieldVideoId,
      //     service: place.fieldVideo.fieldVideoService,
      //     autoplay: false,
      //   };
      // }

      // // photo gallery
      // const photoGalleryImages = [];
      // if ( place.fieldPhotoGallery && place.fieldPhotoGallery.fieldGalleryPhotos ) {
      //   const serializedImages = place.fieldPhotoGallery.fieldGalleryPhotos;
      //   serializedImages.forEach( ( serializedImage, i ) => {
      //     const imageId = serializedImage.id;
      //     const includedImageData = drupalIncluded.find( ( include ) => include.id === imageId );
      //     // i can't find the caption data right now
      //     const image = {
      //       id: imageId,
      //       image: {
      //         modal: includedImageData?.links?.modal?.href,
      //       },
      //       caption: null,
      //     };
      //     photoGalleryImages.push( image );
      //   } );
      // }

      const place = includedPlaceNormalizedData( deserializedPlace, drupalIncluded );
      return place;
    } );
  }

  return places;
}
