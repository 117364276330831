/* eslint-disable import/prefer-default-export */

export function includedPhotoGalleryImagesNormalizedData(
  deserializedImages,
  includedIndex,
  drupalIncluded,
) {
  const images = [];

  if ( deserializedImages ) {
    const serializedIncluded = drupalIncluded[includedIndex];
    const serializedImages = serializedIncluded?.relationships?.field_gallery_photos?.data;

    deserializedImages.forEach( ( deserializedImage, imageIndex ) => {
      const serializedImage = serializedImages[imageIndex];
      const imageId = deserializedImage.id;
      const includedImageData = drupalIncluded.find(
        ( include ) => include.id === imageId,
      );

      const image = {
        id: imageId,
        image: {
          modal: includedImageData?.links?.modal?.href,
        },
        caption: serializedImage?.meta?.alt,
      };
      images.push( image );
    } );
  }
  return images;
}
