import { includedFloorPlansNormalizedData } from '../FloorPlan/IncludedFloorPlansNormalizedData';
import { includedPlacesNormalizedData } from '../Place/IncludedPlacesNormalizedData';
import { includedMediaNormalizedData } from '../MediaPage/IncludedMediaNormalizedData';
import { joinNames } from '../../utils/common';

const JSONAPIDeserializer = require( 'jsonapi-serializer' ).Deserializer;

/**
 * Normalize function for Residents/Prospects.
 */
export const residentCommunityNormalizeData = async ( responseData ) => {
  let normalizedData = {};

  const options = {
    keyForAttribute: 'camelCase',
  };
  const deserializedData = await new JSONAPIDeserializer( options ).deserialize( responseData );

  // There should only be one resident result, but its still in array form.
  const [residentData] = deserializedData;

  // Get names and join them.
  const firstName1 = residentData.fieldFirstName;
  const lastName1 = residentData.fieldLastName;
  const firstName2 = residentData.fieldSpouse?.fieldFirstName;
  const lastName2 = residentData.fieldSpouse?.fieldLastName;
  const firstNameLastName2 = firstName2 && firstName2 ? null : deserializedData.fieldSpouse?.title;
  const names = joinNames( firstName1, lastName1, firstName2, lastName2, firstNameLastName2, '&' );

  // Community data.
  const [communityData] = residentData.ogAudience;

  // put it all together and return it
  normalizedData = {
    community: {
      name: communityData?.title,
      website: communityData?.fieldCommunityWebsite?.uri,
      logoUrl: `${process.env.REACT_APP_YOUR_TOUR_CMS_URL}${communityData.fieldLogo.uri.url}`,
      primaryColor: communityData?.fieldPrimaryColor,
      secondaryColor: communityData?.fieldSecondaryColor,
      greeting: communityData?.fieldPibGreeting,
      phone: communityData?.fieldPhone,
      email: communityData?.fieldEmail,
      amenitiesServicesHTML: communityData?.fieldAmenitiesServices?.processed,
    },
    names,
    createdDate: residentData?.created,
    changedDate: residentData?.changed,
    message: residentData?.fieldFavoritesPageMessage?.value,
  };

  return normalizedData;
};

/**
 * Normalize function for Residents/Prospects favorited floorplans.
 */
export const residentFloorPlansNormalizeData = async ( responseData ) => {
  let favoriteFloorPlans = null;

  // first get what we can out of the serialized data
  const options = {
    keyForAttribute: 'camelCase',
  };

  let deserializedData = await new JSONAPIDeserializer( options ).deserialize( responseData );

  // there should only be one result, but its still in array form
  [deserializedData] = deserializedData;

  // you can't get the image urls from the serialized data
  // so we have to use the raw data
  const drupalIncluded = responseData.included;
  const drupalRawIncluded = responseData.included;

  // change included array into object with id as property name
  // so we can retrieve them by their id later
  const drupalIncludedById = {};
  if ( drupalIncluded && drupalIncluded.length ) {
    drupalIncluded.forEach( ( item ) => {
      const { id } = item;
      drupalIncludedById[id] = item;
    } );
  }

  // get favorite floorplans and normalized the data
  // you have to include the drupalIncluded array because that's where the
  // images live
  const serializedFpData = deserializedData.fieldFavoriteFloorPlans;
  const normalizedFloorPlans = includedFloorPlansNormalizedData(
    serializedFpData,
    drupalIncluded,
    drupalRawIncluded,
  );
  favoriteFloorPlans = normalizedFloorPlans.floorPlans;

  return favoriteFloorPlans;
};

/**
 * Normalize function for Residents/Prospects favorited amenities.
 */
export const residentAmenitiesNormalizeData = async ( responseData ) => {
  // first get what we can out of the serialized data
  const options = {
    keyForAttribute: 'camelCase',
  };
  let deserializedData = await new JSONAPIDeserializer( options ).deserialize( responseData );

  // there should only be one result, but its still in array form
  [deserializedData] = deserializedData;

  // you can't get the image urls from the serialized data
  // so we have to use the raw data
  const drupalIncluded = responseData.included;

  // change included array into object with id as property name
  // so we can retrieve them by their id later
  const drupalIncludedById = {};
  if ( drupalIncluded && drupalIncluded.length ) {
    drupalIncluded.forEach( ( item ) => {
      const { id } = item;
      drupalIncludedById[id] = item;
    } );
  }

  // get favorite floorplans and normalized the data
  // you have to include the drupalIncluded array because that's where the images live
  const favoritePlaces = includedPlacesNormalizedData(
    deserializedData.fieldFavoriteAmenities,
    drupalIncluded,
  );

  return favoritePlaces;
};

/**
 * Normalize function for Residents/Prospects favorited media.
 */
export const residentMediaNormalizeData = async ( responseData ) => {
  // first get what we can out of the serialized data
  const options = {
    keyForAttribute: 'camelCase',
  };
  let deserializedData = await new JSONAPIDeserializer( options ).deserialize( responseData );

  // there should only be one result, but its still in array form
  [ deserializedData ] = deserializedData;

  // you can't get the image urls from the serialized data
  // so we have to use the raw data
  const drupalIncluded = responseData.included;

  // change included array into object with id as property name
  // so we can retrieve them by their id later
  const drupalIncludedById = {};
  if ( drupalIncluded && drupalIncluded.length ) {
    drupalIncluded.forEach( ( item ) => {
      const { id } = item;
      drupalIncludedById[id] = item;
    } );
  }

  // get favorite media and normalized the data
  // you have to include the drupalIncluded array because that's where the
  // images live
  const deserializedMediaData = deserializedData.fieldFavoriteMedia;
  const favoriteMedia = includedMediaNormalizedData( deserializedMediaData, drupalIncluded );
  return favoriteMedia;
};

/**
 * Normalize function for structuring MyResources data.
 */
export const residentMyResourcesNormalizeData = async ( responseData ) => {
  const resources = { links: [], files: [] };
  // The required data is present in 'included' key of the response.
  const drupalIncluded = responseData.included;
  if ( drupalIncluded && drupalIncluded.length > 0 ) {
    drupalIncluded.forEach( ( item ) => {
      const { type } = item;

      // Get Links.
      if ( type === 'node--community' ) {
        if ( item.attributes.field_community_external_links
          && item.attributes.field_community_external_links.length > 0 ) {
          resources.links = item.attributes.field_community_external_links;
        }
      }

      // Get Resources.
      if ( type === 'media--document' ) {
        const fileUuid = item.relationships.field_media_file.data.id;
        const fileItem = drupalIncluded.find(
          ( fileitem ) => fileitem.type === 'file--file' && fileitem.id === fileUuid
        );
        if ( fileItem ) {
          const cmsUrl = process.env.REACT_APP_YOUR_TOUR_CMS_URL;
          resources.files.push( {
            name: item.attributes.name,
            path: cmsUrl + fileItem.attributes.uri.url,
          } );
        }
      }
    } );
  }

  return resources;
};
